import React from 'react'

import { TableHeader, TableRow, FormTable } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const ExportLicenseList = props =>
  <Box className='safearea'>
    <Grid container spacing={1} justifyContent='center' rowSpacing={5}>
      <Grid item xs={11}>
        <Form />
      </Grid>
      <Grid item xs={11}>
        <FormTable
          model_name={'task'}
          loadOnMount
          config={{
            forced_filter: { name: 'export_license' },
            sort: ['createdAt DESC'],
          }}
          tableProps={{
            enablePagination: true,
            allowEditMode: false,
            allowRowGenerate: false
          }}
        >
          <TableHeader><Header /></TableHeader>
          <TableRow><Row /></TableRow>
        </FormTable>
      </Grid>
    </Grid>
  </Box>

export default React.memo(ExportLicenseList)
