import React from 'react'
import { Route, Routes } from '@front/squirtle'

import MessageList from './List'
import MessageSend from './Send'

const MessagePage = props =>
  <Routes>
    <Route index element={<MessageList />} />
    <Route path='/send' element={<MessageSend />} />
  </Routes>

export default React.memo(MessagePage)
