import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(row?.createdAt)} /></TableCell>
  <TableCell size={'small'}><TextCell value={I18n.t(`event.${row?.name}`, { count: 1 })} /></TableCell>
  <TableCell size={'small'}><TextCell value={row?.order?.service?.contract?.formula?.name || row?.order?.do?.commercial_formula?.name} /></TableCell>
  <TableCell size={'small'}><TextCell value={row?.driver?.info?.user_client_id} /></TableCell>
</>

export default React.memo(Row)
