import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = ({ type, ...props }) => <>
  <TableCell align='center'>
    <TextHeader label={I18n.t('step.label', { count: 2 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('hour.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('type.label', { count: 1 })} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('order.client_id')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t(`load.${type}.count`)} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('name.passenger')} />
  </TableCell>
  <TableCell align='center'>
    <TextHeader label={I18n.t('address.label', { count: 1 })} />
  </TableCell>
</>

export default React.memo(Header)
