import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, StepLabel, StepContent } from '@mui/material'
import { ModelAutocompleteField, ModelMenuSelectorField, OptionSelectorField, MenuSelectorField } from '@front/squirtle'

import UserUtils from '@front/volcanion/utils/user'

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <OptionSelectorField
        name='audience'
        options={[
          { label: I18n.t('driver.label.all'), value: 'all' },
          { label: I18n.t('driver.label', { count: 1 }), value: 'custom_drivers' }
        ]}
      />
    </Grid>
  </Grid>

const Row2 = ({ audience, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs hidden={audience !== 'all'}>
      <ModelMenuSelectorField
        name='vehicle_options'
        label={I18n.t('options.vehicleoption.label', { count: -1 })}
        model_name='vehicleoption'
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
        multiple
      />
    </Grid>
    <Grid item xs hidden={audience !== 'all'}>
      <ModelMenuSelectorField
        name='driver_options'
        label={I18n.t('options.useroption.label', { count: -1 })}
        model_name='useroption'
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
        multiple
      />
    </Grid>
    <Grid item xs hidden={audience !== 'all'}>
      <MenuSelectorField
        label={I18n.t('vehicle.pairing_type')}
        name='is_paired'
        noneLabel={I18n.t('all')}
        options={[
          { label: I18n.t('broadcast.paired'), value: 'paired' },
          { label: I18n.t('broadcast.not_paired'), value: 'not_paired' },
        ]}
      />
    </Grid>
    <Grid item xs hidden={audience === 'all'}>
      <ModelAutocompleteField
        name={'driver'}
        label={I18n.t('driver.search_multiple')}
        model_name={'user'}
        searchMode='search'
        searchQuery={'='}
        autocompleteMutator={q => ({
          or: [
            { info: { user_client_id: { startsWith: _.split(q, ' ') } } },
            { info: { first_name: { startsWith: _.split(q, ' ') } } },
            { info: { last_name: { startsWith: _.split(q, ' ') } } }]
        })}
        labelKeys={['label']}
        multiple
        loadOnFocus
        config={{
          forced_filter: { driverinfo: { '!': null } },
          populate: ['info', 'customerinfo'],
          mutator: user => ({
            label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.info?.user_client_id }),
            ...user
          })
        }}
        selectSingleOption
        debounceTime={400}
      />
    </Grid>
  </Grid>

const Row3 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelMenuSelectorField
        name='vehicletypes'
        label={I18n.t('vehicletype.label', { count: -1 })}
        model_name='vehicletype'
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
        multiple
        noneFirst
        noneLabel={I18n.t('all')}
      />
    </Grid>
  </Grid>


const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('message.recipients')}</Typography>
  </StepLabel>
  <StepContent >
    <Grid container rowSpacing={2}>
      <Row1 {...props} />
      <Row2 {...props} />
      <Row3 {...props} />
    </Grid>
  </StepContent>
</>

export default React.memo(PrimarySection)
