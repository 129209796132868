import FormatUtils from '@front/squirtle/utils/format'
import _ from 'lodash'

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        active: [0, 1],
        name: [
          'driver_request_for_a_not_loaded',
          'driver_request_for_redispatch',
          'driver_request_for_replacement',
          'driver_request_to_be_called',
          'order_not_served',
          'pairing_request'
        ],
        ...filter
      }
    }
  }

  static formToFilterHandler(codeIntlPhone) {
    return function formToFilter(values) {
      const {
        createdAt_start,
        createdAt_end,
        ...rest
      } = values

      return _.merge({},
        { action: { '!': null } },
        rest,
        _.has(rest, 'do.auth.gsm') ? { do: { auth: { gsm: FormatUtils.parsePhoneNumber(rest?.do?.auth?.gsm, codeIntlPhone) } } } : {},
        { createdAt: FormatUtils.intervalFilter(createdAt_start, createdAt_end) }
      )
    }
  }
}

export default Callbacks
