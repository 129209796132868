import React, { useCallback } from 'react'

import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const [billingExport] = hooks.useModelFunction('order', 'billingExport')
  const formProps = {
    name: 'export_billing_form',
    model_name: 'task',
    onSubmit: useCallback(Callbacks.onSubmitHandler(billingExport), [billingExport]),
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
