import React, { useState } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { Box, Typography, Badge, } from '@mui/material'
import { FormTable, ExpandPanelComponent, ExpandPanelContent, ExpandPanelTitle, TableHeader, TableRow } from '@front/squirtle'

import Header from './Header'
import Row from './Row'

const IncidentListHeader = props => {
  const incidentsCount = hooks.useSearchListenerCount('event', 'filtered_incident')
  return <Box sx={{ mt: 2 }}>
    <Badge color={'error'} badgeContent={incidentsCount} sx={{ m: 0 }}>
      <Typography variant={'h5'}>
        {I18n.t('incident.current', { count: incidentsCount })}
      </Typography>
    </Badge>
  </Box>
}

const IncidentList = ({ onRowClick }) => {
  const [open, setOpen] = useState(true)
  return (
    <ExpandPanelComponent open={open} toggleOpen={setOpen}>
      <ExpandPanelTitle><IncidentListHeader /></ExpandPanelTitle>
      <ExpandPanelContent>
        <FormTable
          model_name={'event'}
          loadOnMount={false}
          config={{
            populate: [
              'order.do.commercial_formula',
              'order.service.contract.formula',
              'driver.info'
            ],
            listener_type: 'attached',
            search_id: 'filtered_incident',
            sort: ['producedAt ASC']
          }}
          tableProps={{
            selectRowStateID: 'selected_incident',
            onRowClick,
            stickyHeader: true,
            enableRowActions: true,
            enableGlobalActions: false,
            enableToolbar: false,
            enablePagination: false,
            allowEditMode: false,
            allowRowGenerate: false,
            disableCancelRowLabel: false,
            disableSaveRowLabel: false,
            containerProps: { sx: { height: 'max-content' } }
          }}
        >
          <TableHeader><Header /></TableHeader>
          <TableRow><Row /></TableRow>
        </FormTable>
      </ExpandPanelContent>
    </ExpandPanelComponent>
  )
}

export default IncidentList
