import React from 'react'
import { I18n } from '@front/volcanion'

import { TextField } from '@front/squirtle'

const RefusePopup = props => <>
  <TextField
    label={I18n.t('incident.popup.refuse.comment')}
    name={'message'}
    multiline
  />
</>

export default RefusePopup
