import React from 'react'
import { Route, Routes } from '@front/squirtle'

import ExportLicenseList from './List'

const ExportLicensePage = props =>
  <Routes>
    <Route index element={<ExportLicenseList />} />
  </Routes>


export default React.memo(ExportLicensePage)
