import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell, AddressCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row }) => {
  const [{ name: user_name }] = hooks.useUserInfo(row?.order?.do?.user_id, row?.order?.service?.companyservice_id)
  return <>
    <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(row?.createdAt)} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`event.${row?.name}`, { count: 1 })} /></TableCell>
    <TableCell size={'small'}><TextCell value={user_name} /></TableCell>
    <TableCell size={'small'}><TextCell value={row?.order?.service?.contract?.formula?.name || row?.order?.do?.commercial_formula?.name} /></TableCell>
    <TableCell size='small'>
      <AddressCell source={row?.order?.source} destination={row?.order?.destination} />
    </TableCell>
  </>
}


export default React.memo(Row)
