import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { TableHeader, TableRow, SearchFrame, SearchTable, SearchForm, FormContent } from '@front/squirtle'
import { Box, Typography, StepLabel, StepContent } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'

import Callbacks from './callbacks'

const StepList = ({ record_id, type, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])

  return <>
    <StepLabel>
      <Typography variant='h5'> {I18n.t('step.label', { count: 1 })} </Typography>
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
        <SearchFrame
          model_name={'step'}
          populate={[
            'order.source',
            'order.destination',
            'order.assigned_transport',
            'order.commercial_package',
            'order.loads',
            'order.reason'
          ]}
          sort={['index ASC']}
          allowCreate={false}
        >
          <SearchForm
            filterToForm={filterToForm}
            formToFilter={formToFilter}
            submitOnMount
            disableButton
          >
            <FormContent><Form /></FormContent>
          </SearchForm>
          <SearchTable
            tableProps={{
              onRowClick: _.noop,
              enablePagination: false
            }}
          >
            <TableHeader><Header type={type} /></TableHeader>
            <TableRow><Row /></TableRow>
          </SearchTable>
        </SearchFrame>
      </Box>
    </StepContent>
  </>
}

export default React.memo(StepList)
