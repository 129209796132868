import React from 'react'
import Component from './component'
import withContainer from './container'

export {
  Component,
  withContainer,

}
export default React.memo(React.memo(withContainer(Component)))
