const formatCreatedAt = (start, end) => {
  if (!!start && !!end) return { '>=': start, '<=': end }
  else if (!!start && !end) return { '>=': start }
  else if (!start && !!end) return { '<=': start }
  else return undefined
}

const Aux = {
  formatCreatedAt
}

export default Aux
