import React, { useCallback, useMemo } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row }) => {
  const [{ name: operator_name }] = hooks.useCustomerInfo(row?.from?.user_id)

  const driver_options = _.map(_.get(row, 'driver_options'), 'name_translated')
  const vehicle_options = _.map(_.get(row, 'vehicle_options'), 'name_translated')

  const formatOptions = useCallback((label, options = '-') => _.join([label, options], ': '), [])

  const formattedDriverOptions = useMemo(() => formatOptions(I18n.t('options.useroption.label.other'), driver_options), [_.join(driver_options, ','), formatOptions])
  const formattedVehicleOptions = useMemo(() => formatOptions(I18n.t('options.vehicleoption.label.other'), vehicle_options), [_.join(vehicle_options, ','), formatOptions])

  return <>
    <TableCell><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'createdAt'))} /></TableCell>
    <TableCell><TextCell value={I18n.t(`driver.${_.get(row, 'type')}`)} /></TableCell>
    <TableCell>
      <TextCell value={_.join([I18n.t('target'), I18n.t(`broadcast.${_.get(row, 'audience')}`)], ': ')} />
      {!_.isEmpty(driver_options) && <TextCell value={formattedDriverOptions} />}
      {!_.isEmpty(vehicle_options) && <TextCell value={formattedVehicleOptions} />}
    </TableCell>
    <TableCell><TextCell value={operator_name} /></TableCell>
    <TableCell><TextCell value={_.get(row, 'title')} /></TableCell>
    <TableCell><TextCell value={_.get(row, 'body')} /></TableCell>
  </>
}

export default React.memo(Row)
