
import React from 'react'
import Component from './component'
import withContainer from './container'

import SendCommentPopup from './SendComment'
import RefusePopup from './Refuse'
import HistoryPopup from './History'
import shortcuts from './shortcuts'


export {
  Component,
  withContainer,
  SendCommentPopup,
  RefusePopup,
  shortcuts,
  HistoryPopup
}

export default React.memo(React.memo(withContainer(Component)))
