import React from 'react'
import { hooks } from '@front/volcanion'

import { Box, Link, Typography, TableCell, Grid } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'
import OrderUtils from '@front/volcanion/utils/order'
import GeoUtils from '@front/volcanion/utils/geo'

const Row = ({ row }) => {
  const type = OrderUtils.getOrderType(_.get(row, 'first_step.order.do.auth.application.name'), _.get(row, 'first_step.order.commercial_package.load_type'))
  const orderStatus = hooks.useOrderStatus(_.get(row, 'first_step.order'), { translated: true })
  return <>
    <TableCell size={'small'}>
      <Typography
        sx={{
          color: OrderUtils.getColorTheme(type),
          fontWeight: 800,
          fontSize: 'subtitle1.fontSize',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Link sx={{ color: 'inherit' }}
          target='_blank'
          onClick={(e) => {
            window.open(`/order/${_.get(row, 'first_step.order.order_id')}`, '_blank')
            e.stopPropagation()
          }}
        >
          {_.get(row, 'first_step.order.order_client_id')}
        </Link>
      </Typography>
    </TableCell>
    <TableCell size={'small'}>
      <TextCell value={(_.get(row, 'orders') || []).length} sx={{ color: 'primary.main', fontWeight: 600 }} />
    </TableCell>
    <TableCell size={'small'}>
      <TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'first_step.order.requestedAt'))} />
    </TableCell>
    <TableCell size={'small'}>
      <Grid container spacing={1}>
        <Grid item xs>
          <Box sx={{ display: 'flex', p: '3px', alignItems: 'center' }}>
            <Box sx={{ width: 9, height: 9, borderRadius: '50%', boxShadow: 3, bgcolor: 'green', opacity: 0.9, mr: 1 }} />
            <TextCell value={GeoUtils.format(_.get(row, 'first_step.order.source'))} sx={{ textAlign: 'left' }} />
          </Box>
        </Grid>
        <Grid item xs>
          <Box sx={{ display: 'flex', p: '3px', alignItems: 'center' }}>
            <Box sx={{ width: 9, height: 9, borderRadius: '50%', boxShadow: 3, bgcolor: 'red', opacity: 0.9, mr: 1 }} />
            <TextCell value={GeoUtils.format(_.get(row, 'first_step.order.destination'))} sx={{ textAlign: 'left' }} />
          </Box>
        </Grid>
      </Grid>
    </TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'first_step.order.assigned_transport.driver.info.user_client_id')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'first_step.order.commercial_package.name_translated')} /></TableCell>
    <TableCell size={'small'}>
      <TextCell
        value={orderStatus}
        sx={{
          fontSize: 17,
          letterSpacing: 1.5,
          fontWeight: 400,
          color: hooks.useOrderStatusColor(_.get(row, 'first_step.order'))
        }}
      />
    </TableCell>
  </>
}

export default React.memo(Row)
