import React from 'react'
import { ModelMenuSelectorField, TextField } from '@front/squirtle'
import { Grid } from '@mui/material'

const SendCommentPopup = props => <>
  <Grid container rowSpacing={1.5} justifyContent='center'>
    <Grid item xs={6}>
      <TextField label="Titre" name={'title'} disabled />
    </Grid>
    <Grid item xs={8}>
      <ModelMenuSelectorField
        label={"Type de commentaire"}
        name={'type'}
        model_name={'commenttype'}
        labelKeys={['label']}
        searchMode={'search'}
        required
        config={{
          initial_filter: { category: 'JFPNk9Qxj' },
          sort: ['display_index ASC'],
          mutator: elem => ({ ...elem, label: _.upperFirst(_.get(elem, `name_translated`)) })
        }}
      />
    </Grid>
    <Grid item xs={10}>
      <TextField label={`Message `} name={'text'} multiline minRows={2} />
    </Grid>
  </Grid>
</>

export default SendCommentPopup