import { FormatUtils } from "@front/volcanion/utils"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'deleted', 'suspended'],
        ...filter
      }
    }
  }

  static formToFilterHandler(group) {
    return function formToFilter(values, extra, meta, state) {
      const { status, ...rest } = values
      return {
        ...rest,
        ...FormatUtils.getParseFilterStatus(status),
        group,
      }
    }
  }

  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      if (_.get(values, 'sortBy') === 'status') return { sort: ['active', 'deleted'] }
      return { sort: [_.get(values, 'sortBy')], }
    }
  }
}

export default Callbacks
