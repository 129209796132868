const getPriceByPassenger = record =>
  _.sum(
    _.filter(
      _.filter(_.get(record, 'steps'), ['order.payment_type', 'immediate_sender']),
      ['type', 'dropoff']
    ), ['order.assigned_transport.payment.base'])


const Aux = {
  getPriceByPassenger
}

export default Aux
