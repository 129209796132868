import React, { useMemo } from 'react'
import { I18n, hooks } from '@front/volcanion'
import _ from 'lodash'
import { TableCell, Button, Box } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'
import { Loader, DateTimeField } from '@front/squirtle'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row, ...props }) => {
  const onClick = hooks.useFileDownload('GET', 'task/download', { task_id: _.get(row, 'task_id') })

  const orderState = useMemo(() => {
    if (_.get(row, 'status') === 'failed') {
      return I18n.t('error.label.one')
    } else return I18n.t('wait.in')
  }, [_.get(row, 'status')])

  return <>
    <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'createdAt'))} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`export.${!!_.get(row, 'input.auto') ? 'auto' : 'manual'}`)} /></TableCell>
    <TableCell size={'small'}>
      <DateTimeField
        name='input.start_date'
        emptyValue='-'
        inputProps={{ style: { textAlign: 'center' } }}
        compact
      />
    </TableCell>
    <TableCell size={'small'}>
      <DateTimeField
        name='input.end_date'
        emptyValue='-'
        inputProps={{ style: { textAlign: 'center' } }}
        compact
      />
    </TableCell>
    <TableCell sx={{ textAlign: 'center' }}>
      <Loader isLoading={_.get(row, 'status') === 'running'}>
        {_.get(row, 'status') === 'complete'
          ? <Button size='small' onClick={() => onClick()} variant='contained'> {I18n.t('action.download')}</Button>
          : <TextCell value={orderState} />
        }
      </Loader>
      {_.get(row, 'status') === 'running' && <Box>{`${_.get(row, 'progress', 0)} %`}</Box>}
    </TableCell>
  </>
}

export default React.memo(Row)
