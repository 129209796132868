import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, StepLabel, StepContent } from '@mui/material'
import { DateTimeField, OptionSelectorField, TextField } from '@front/squirtle'

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <OptionSelectorField
        name='type'
        multiple
        options={[
          { label: I18n.t('message.alert_real_time'), value: 'alert' },
          { label: I18n.t('message.memorized'), value: 'message' }
        ]}
      />
    </Grid>
  </Grid >

const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name='title'
        label={I18n.t('title.label', { count: 1 })}
        placeholder={I18n.t('message.contain_word')}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='body'
        label={I18n.t('message.label', { count: 1 })}
        placeholder={I18n.t('message.contain_word')}

      />
    </Grid>
  </Grid >

const Row3 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <DateTimeField
        name='createdAt_start'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY',
        }}
        timeProps={{
          label: I18n.t('prefix.to'),
          minTime: null
        }}
        default_time='00:00'
      />
    </Grid>
    <Grid item xs>
      <DateTimeField
        name='createdAt_end'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.to'),
          minTime: null
        }}
        default_time='23:59'
      />
    </Grid>
  </Grid >

const MessageSection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('message.label', { count: 1 })}</Typography>
  </StepLabel>
  <StepContent >
    <Grid container rowSpacing={2}>
      <Row1 />
      <Row2 />
      <Row3 />
    </Grid>
  </StepContent>
</>

export default React.memo(MessageSection)
