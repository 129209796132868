import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Callbacks from './callbacks'
import Form from './Form'
import Header from './Header'
import Row from './Row'

const MessageList = props => {
  const navigate = hooks.useNavigate()
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const handleSend = useCallback(() => navigate('/message/send'), [])
  return <>
    <Box className='safearea'>
      <Grid container justifyContent='center' >
        <Grid item xs={11}>
          <SearchFrame
            model_name='broadcast'
            populate={['from.info', 'driver_options', 'vehicle_options']}
            createButtonProps={{ label: I18n.t('message.send'), onClick: handleSend }}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              submitOnMount
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable tableProps={{ onRowClick: false }}>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Grid>
      </Grid>
    </Box >
  </>
}

export default React.memo(MessageList)