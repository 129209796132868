import React, { useMemo } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [title, body] = hooks.useFieldValues(['title', 'body'])

  const count_title = useMemo(() => _.size(title), [title])
  const count_body = useMemo(() => _.size(body), [body])

  const mergedProps = {
    count_title,
    count_body
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
