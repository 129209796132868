import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [model_name] = hooks.useFormState('model_name')
  const requestSubmit = hooks.useFormFunction('requestSubmit')

  const mergedProps = {
    model_name,
    requestSubmit
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
