import React, { useCallback, useMemo } from 'react'

import { Loader } from '@front/squirtle'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => ({ goBackPath }) => {
  const form_name = hooks.useFormName()
  const navigate = hooks.useNavigate()
  const isReady = hooks.useFormStatus('isReady')
  const isSubmitting = hooks.useFormStatus('isSubmitting')
  const [, { openDialog }] = hooks.useDialogs()
  const requestSubmit = hooks.useGlobalFormFunction(form_name, 'requestSubmit')
  const [vehicle_options, driver_options, drivers] = hooks.useFieldValues(['vehicle_options', 'driver_options', 'drivers'])

  const isAudienceContainAllDriver = useMemo(() => _.isEmpty(_.compact(_.flatten([vehicle_options, driver_options, drivers]))), [vehicle_options, driver_options, drivers])

  const goBack = useCallback(Callbacks.goBackHandler(goBackPath, navigate), [goBackPath, navigate])
  const onSubmit = useCallback(Callbacks.onSubmitHandler(isAudienceContainAllDriver, requestSubmit, openDialog), [isAudienceContainAllDriver, requestSubmit, openDialog])

  const mergedProps = {
    goBack,
    onSubmit,
    requestSubmit,
    isSubmitting
  }

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} />
  </Loader>
}

export default withContainer
