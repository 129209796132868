import React from 'react'
import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'


const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [type] = hooks.useFormState('type')
  const [status] = hooks.useFormState('status')

  const mergedProps = {
    type,
    status,
  }

  return <Loader isLoading={!isReady}>
    <Component {...mergedProps} {...props} />
  </Loader>
}

export default withContainer
