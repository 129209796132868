import React, { useCallback } from 'react'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'

import Callbacks from './callbacks'

const JobList = props => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])

  return <>
    <Box className='safearea'>
      <SearchFrame
        model_name={'job'}
        populate={[
          'first_step.order.do.auth.application',
          'first_step.order.commercial_package',
          'first_step.order.assigned_transport.driver.info',
          'first_step.order.destination',
          'first_step.order.source',
          'orders'
        ]}
        allowCreate={false}
        sort={['first_step.order.requestedAt DESC']}
      >
        <SearchForm
          getButtonStyleProps={() => ({ my: 1 })}
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          submitOnMount
        >
          <FormContent><Form /></FormContent>
        </SearchForm>
        <SearchTable>
          <TableHeader><Header /></TableHeader>
          <TableRow><Row /></TableRow>
        </SearchTable>
      </SearchFrame>
    </Box>
  </>
}

export default React.memo(JobList)
