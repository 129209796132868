import React from 'react'
import { Route, Routes } from '@front/squirtle'

import License from './Details'
import LicenseList from './List'

const LicensePage = props =>
  <Routes>
    <Route index element={<LicenseList />} />
    <Route path=':license_id' element={<License />} />
    <Route path='create' element={<License />} />
  </Routes>


export default React.memo(LicensePage)
