import React from 'react'

import { MapFactory } from '@front/squirtle'


const HomePage = (props) => {
  return (
    <MapFactory.MapContainer
      mapStyle={{ height: window.innerHeight }}
      mapId='mainScreen'
    >

    </MapFactory.MapContainer>
  )
}

export default React.memo(HomePage)
