import React from 'react'
import { Route, Routes } from '@front/squirtle'

import ExportList from './List'

const ExportPage = props =>
  <Routes>
    <Route index element={<ExportList />} />
  </Routes>


export default React.memo(ExportPage)
