import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'><TextHeader label={I18n.t('contract.id')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('contract.license_id')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('date.delivery')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('category.label', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('vehicle.usual')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('status.label', { count: 1 })} /></TableCell>
</>

export default React.memo(Header)
