import React from 'react'
import { Routes, Route } from '@front/squirtle'

import JobList from './List'
import JobDetails from './Details'

const JobPage = props =>
  <Routes>
    <Route index element={<JobList />} />
    <Route path='/create' element={<JobDetails />} />
    <Route path='/:job_id' element={<JobDetails />} />
  </Routes>

export default React.memo(JobPage)
