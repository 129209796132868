
import React from 'react'

import { hooks } from '@front/volcanion/'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [type] = hooks.useFormState('type')

  const mergedProps = {
    record_id,
    type
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
