import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import PrimarySection from './Section/Primary'
import MessageSection from './Section/Message'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}>{I18n.t('message.search')}</Typography>
  </Grid>

const SearchForm = props =>
  <Box sx={{ boxShadow: 6, borderRadius: 1, p: 2 }}>
    <Grid container>
      <Title />
      <GridStepper groupsCount={[2]} activeStep={0} orientation="vertical">
        <Step> <PrimarySection /> </Step>
        <Step> <MessageSection /> </Step>
      </GridStepper>
    </Grid>
  </Box>

export default React.memo(SearchForm)
