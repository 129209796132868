import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import LicenseDetails from '@abra/frames/License'

const LicensePage = props => {
  const { license_id, group_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <LicenseDetails record_id={license_id} group_id={group_id} />
    </Box>
  )
}

export default React.memo(LicensePage)
