class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        member_status: 'all',
        ...filter
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { license_client_id, member_status, ...rest } = values
      if (_.has(rest, 'name'))
        _.merge(rest, { name: { startsWith: rest?.name } })
      if (_.has(rest, 'family'))
        _.merge(rest, { family: { contains: rest?.family } })

      return {
        member_status: member_status === 'all'
          ? undefined
          : member_status === 'active',
        licenses: _.has(values, 'license_client_id') ? _.compact([{ license_client_id }]) : undefined,
        ...rest
      }
    }
  }
}

export default Callbacks
