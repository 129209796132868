import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Box, Grid } from '@mui/material'
import { OptionSelectorField, TextField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('group.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name={'group_client_id'} label={I18n.t('group.id')} />
    </Grid>
    <Grid item xs>
      <TextField name={'company_type'} label={I18n.t('company.type')} />
    </Grid>
    <Grid item xs>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <OptionSelectorField
        name={'member_status'}
        label={I18n.t('status.label', { count: 1 })}
        valueType={'checkbox'}
        multiple={false}
        options={[
          { label: I18n.t('status.all'), value: 'all' },
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.unactive'), value: 'inactive' }
        ]}
      />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name={'address'} label={I18n.t('address.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name={'family'} label={I18n.t('family')} />
    </Grid>
    <Grid item xs>
      <TextField name={'license_client_id'} label={I18n.t('contract.id')} />
    </Grid>
    <Grid item xs>
      <TextField name={'tva_intracommunity_number'} label={I18n.t('group.tva_id')} />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container justifyContent={'center'} rowSpacing={3}>
          <Title />
          <Row1 />
          <Row2 />
        </Grid>
      </Box>
    </Grid>
  </Grid >

export default React.memo(SearchForm)
