import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { MenuSelectorField, OptionSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('contract.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('sort.by')}
        name={'sortBy'}
        options={[
          { label: I18n.t('date.create'), value: 'createdAt' },
          { label: I18n.t('status.label', { count: 1 }), value: 'status' }
        ]}
      />
    </Grid>
    <Grid item xs>
      <OptionSelectorField
        name={'status'}
        label={I18n.t('status.label', { count: 1 })}
        valueType={'checkbox'}
        multiple
        options={[
          { label: I18n.t('status.active'), color: 'green', value: 'active' },
          { label: I18n.t('status.suspended'), color: 'orange', value: 'suspended' },
          { label: I18n.t('status.deleted'), color: 'red', value: 'deleted' },
        ]}
        getOptionLabelProps={({ color }) => ({ componentsProps: { typography: { color } } })}
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container justifyContent={'center'} rowSpacing={3}>
          <Title />
          <Row1 />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
