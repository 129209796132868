import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Box } from '@mui/material'
import { ExpandPanelComponent, ExpandPanelContent, ExpandPanelTitle, OptionSelectorField, ModelOptionSelectorField } from '@front/squirtle/'

const LabelComponent = ({ label }) =>
  <Typography variant={'h6'} sx={{ fontWeight: 300 }}>
    {label}
  </Typography>

const IncidentSearch = ({ nameOpen, setNameOpen, formulaOpen, setFormulaOpen, onSubmit }) =>
  <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1, flexDirection: 'column' }}>
    <Typography variant={'h5'}> {I18n.t('filter.label', { count: 2 })}</Typography>
    <ExpandPanelComponent open={nameOpen} toggleOpen={setNameOpen}>
      <ExpandPanelTitle><LabelComponent label={'Type d\'incident'} /></ExpandPanelTitle>
      <ExpandPanelContent>
        <OptionSelectorField
          name={'name'}
          options={[
            { label: I18n.t('driver.pairing'), value: 'pairing_request' },
            { label: I18n.t('incident.driver_request_to_be_called.short'), value: 'driver_request_to_be_called' },
            { label: I18n.t('incident.driver_request_for_replacement.short'), value: 'driver_request_for_replacement' },
            { label: I18n.t('incident.driver_request_for_a_not_loaded.short'), value: 'driver_request_for_a_not_loaded' },
            { label: I18n.t('incident.order_not_served'), value: 'order_not_served' },
            { label: I18n.t('incident.request_delete'), value: 'request_delete' },
            { label: I18n.t('incident.driver_request_for_redispatch.short'), value: 'driver_request_for_redispatch' }
          ]}
          multiple
          orientation='vertical'
          afterUserChange={onSubmit}
        />
      </ExpandPanelContent>
    </ExpandPanelComponent >
    <ExpandPanelComponent open={formulaOpen} toggleOpen={setFormulaOpen}>
      <ExpandPanelTitle><LabelComponent label={'Form. comm.'} /></ExpandPanelTitle>
      <ExpandPanelContent>
        <ModelOptionSelectorField
          name={'order.do.commercial_formula'}
          model_name={'commercialformula'}
          labelKeys={['name']}
          searchMode={'search'}
          config={{ initial_filter: {} }}
          multiple
          orientation='vertical'
          afterUserChange={onSubmit}
        />
      </ExpandPanelContent>
    </ExpandPanelComponent >
  </Box>

export default React.memo(IncidentSearch)
