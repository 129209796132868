import React from 'react'
import { I18n, CDNImage } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import CPUtils from '@front/squirtle/utils/CPUtils'

import { TextField, DateTimeField, ModelMenuSelectorField } from '@front/squirtle/'

const Block1 = ({ client_type_icon }) => <>
  <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
    <Grid container>
      <Grid container item xs={12} alignItems='center' spacing={2}>
        <Grid item xs={1}>
          <CDNImage name={client_type_icon} category='packages' style={{ height: 'auto', width: '100%', maxHeight: 30 }} />
        </Grid>
        <Grid container item xs={10}>
          <Grid item xs={12}>
            <ModelMenuSelectorField
              name={'commercial_package'}
              label={I18n.t('commercialpackage.label', { count: 1 })}
              model_name={'commercialpackage'}
              labelKeys={['label']}
              config={{ mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp }) }}
              valueDecoratorProps={{ sx: { wordWrap: "break-word" } }}
            />
          </Grid>
          <Grid container item xs={12} md={3} spacing={1}>
            <Grid item xs={7}>
              <DateTimeField
                name={'requestedAt'}
                dateProps={{
                  label: I18n.t('date.label', { count: 1 }),
                }}
                compact
                disableOpenPicker
              />
            </Grid>
            <Grid item xs>
              <TextField
                emptyValue={33}
                name={'distance'}
                label={I18n.t('distance.label', { count: 1 })}
                type={'number'}
                _adornment={{
                  sx: { fontSize: 'subtitle.fontSize', color: 'text.label' }
                }}
                InputProps={{
                  inputProps: { style: { textAlign: 'right' } },
                  endAdornment: I18n.t('prefix.km')
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
</>

const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('info.general')} </Typography>
  </StepLabel>
  <StepContent >
    <Grid container spacing={2}>
      <Grid item xs>
        <Block1 {...props} />
      </Grid>
    </Grid>
  </StepContent>
</>

export default React.memo(PrimarySection)
