class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }

  static formToFilterHandler(job) {
    return function formToFilter(values, extra, meta, state) {
      return {
        job,
        ...values
      }
    }
  }

}

export default Callbacks
