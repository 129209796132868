import React from 'react'
import { hooks, I18n } from '@front/volcanion'

import ConstantUtils from '@front/volcanion/utils/constants'
import CPUtils from '@front/squirtle/utils/CPUtils'

import { Box, Typography, Grid } from '@mui/material'

import { DateTimeField, TextField, MenuSelectorField, ModelMenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('job.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name={'orders.order_client_id'} label={I18n.t('order.id.alter')} />
    </Grid>
    <Grid item xs>
      <TextField name={'orders.source.name'} label={I18n.t('address.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name={'orders.assigned_transport.driver.info.user_client_id'} label={I18n.t('driver.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name={'orders.assigned_transport.vehicle.info.plaque'} label={I18n.t('vehicle.label', { count: 1 })} />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        name={'orders.commercial_package.load_type'}
        label={I18n.t('transport.type')}
        multiple
        options={hooks.useLoads()}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'orders.commercial_formula'}
        label={I18n.t('commercial_formula.label', { count: 1 })}
        model_name='commercialformula'
        labelKeys={['name']}
        multiple
        searchMode={'search'}
        loadOnMount
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'orders.commercial_package.commercial_package_id'}
        label={I18n.t('commercialpackage.label', { count: 2 })}
        model_name='commercialpackage'
        labelKeys={['label']}
        searchMode={'search'}
        multiple
        config={{
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp })
        }}
        loadOnMount
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name={'orders.status'}
        label={I18n.t('status.label', { count: 1 })}
        multiple
        options={_.map(ConstantUtils.getOrderStatuses(), status =>
          ({ label: I18n.t(`order.status.${status}`), value: status })
        )}
      />
    </Grid>
  </Grid>

const Row3 = props =>
  <Grid container item xs={12} spacing={1} justifyContent='center'>
    <Grid item xs={3} >
      <DateTimeField
        name='requestedAt_start'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY',
        }}
        timeProps={{
          label: I18n.t('prefix.to'),
          minTime: null
        }}
        default_time='00:00'
      />
    </Grid>
    <Grid item xs={3} >
      <DateTimeField
        name='requestedAt_end'
        dateProps={{
          label: I18n.t('date.search'),
          inputFormat: 'DD/MM/YYYY'
        }}
        timeProps={{
          label: I18n.t('prefix.to'),
          minTime: null
        }}
        default_time='23:59'
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent='center'>
    <Grid item xs={9}>
      <Box sx={{ boxShadow: 6, borderRdisu: 1, p: 2 }}>
        <Grid container rowSpacing={1} justifyContent='center'>
          <Title />
          <Row1 />
          <Row2 />
          <Row3 />
        </Grid>
      </Box>
    </Grid>
  </Grid>


export default React.memo(SearchForm)
