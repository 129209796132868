const buttons = [
  [
    { key: 1, hotkey: 'F1', action: 'help' },
    { key: 2, hotkey: 'F2', action: 'open_comment_popup' },
    { key: 3, hotkey: 'F3', action: 'open_order_detail_page' },
    { key: 4, hotkey: ['Alt', 'F3'], action: 'open_order_detail_page_with_client_id' }
  ],
  [
    { key: 5, hotkey: 'F5', action: 'display_map' },
    { key: 6, hotkey: ['Alt', 'F5'], action: 'open_order_search_page' },
    { key: 7, hotkey: 'F7', action: 'call_driver' }
  ],
  [
    { key: 8, hotkey: 'F9', action: 'call_customer' },
    { key: 9, hotkey: ['Alt', 'F9'], action: 'open_order_search_with_all_orders_of_driver' },
    { key: 10, hotkey: ['Alt', 'F10'], action: 'open_driver_detail_page' },
    { key: 11, hotkey: ['Alt', 'F11'], action: 'open_message_search_page' }
  ],
  [
    { key: 12, hotkey: 'F12', action: 'approve' },
    { key: 13, hotkey: ['Ctrl', 'F12'], action: 'refuse' }
  ]
]

export default buttons
