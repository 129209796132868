import React, { useCallback } from 'react'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid, Box } from '@mui/material'
import { hooks } from '@front/volcanion'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import Callbacks from './callbacks'


const ExportList = props => {
  const [router_state] = hooks.useRouterState()
  const { operator, name } = router_state || {}
  const filterToForm = useCallback(Callbacks.filterToFormHandler(operator, name), [operator, name])

  return (
    <Grid container justifyContent='center' alignItems='center' sx={{ bgcolor: 'background.primary' }}>
      <Grid item xs={11}>
        <Box sx={{ mt: 2 }}>
          <SearchFrame
            model_name={'task'}
            populate={['operator.info']}
            sort={['createdAt DESC']}
          >
            <SearchForm
              submitOnMount
              filterToForm={filterToForm}
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              tableProps={{
                onRowClick: undefined
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(ExportList)
