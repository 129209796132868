import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { TextField, ModelMenuSelectorField } from '@front/squirtle/'

const Block1 = props =>
  <Grid container item xs={12}>
    <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            label={I18n.t('price.order', { count: 1 })}
            name={'price.order'}
            type={'number'}
            _adornment={{ sx: { fontSize: 'subtitle.fontSize', color: 'text.label' } }}
            InputProps={{
              inputProps: { style: { textAlign: 'right' } },
              endAdornment: I18n.t('fiat.eur.sign')
            }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            label={I18n.t('quotation.amount_cashed')}
            name={'price.cashed'}
            type={'number'}
            _adornment={{ sx: { fontSize: 'subtitle.fontSize', color: 'text.label' } }}
            InputProps={{
              inputProps: { style: { textAlign: 'right' } },
              endAdornment: I18n.t('fiat.eur.sign')
            }}
          />
        </Grid >
        <Grid item xs>
          <TextField
            name={'price.driver'}
            label={I18n.t('price.driver', { count: 1 })}
            type={'number'}
            _adornment={{ sx: { fontSize: 'subtitle.fontSize', color: 'text.label' } }}
            InputProps={{
              inputProps: { style: { textAlign: 'right' } },
              endAdornment: I18n.t('fiat.eur.sign')
            }}
          />
        </Grid >
      </Grid >
    </Box>
  </Grid >

const Block2 = props => <>
  <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <ModelMenuSelectorField
          name={'driver_opts'}
          label={I18n.t('options.useroption.label', { count: 2 })}
          model_name={'useroption'}
          labelKeys={['name_translated']}
          multiple
        />
      </Grid>
      <Grid item xs={12}>
        <ModelMenuSelectorField
          name={'vehicle_opts'}
          label={I18n.t('options.vehicleoption.label', { count: 2 })}
          model_name={'vehicleoption'}
          labelKeys={['name_translated']}
          multiple
        />

      </Grid>
    </Grid>
  </Box>
</>

const QuotationSection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('billing.info', { count: 1 })} </Typography>
  </StepLabel>
  <StepContent >
    <Grid container spacing={2} >
      <Grid item xs={5}> <Block1 {...props} /> </Grid>
      <Grid item xs> <Block2 {...props} /> </Grid>
    </Grid>
  </StepContent>
</>

export default React.memo(QuotationSection)
