import React from 'react'
import { I18n } from '@front/volcanion'

import { ExpandPanelComponent, ExpandPanelTitle, ExpandPanelContent } from '@front/squirtle'
// import MapComponent from '@front/squirtle/components/OldMap'
import { Typography, Grid } from '@mui/material'

import { PairingIncident, DefaultIncident } from '@abra/panels/Incident/'
import { IncidentHeader, OrderHeader } from '@abra/elements/headers/'

import OrderDetails from '@abra/frames/Order'
import DialogManager from './Popup'

const MapHeader = props => <Typography color={'primary'} sx={{ fontWeight: 300, fontSize: '1.2vw' }}> {I18n.t('incident.title.map')} </Typography>

const IncidentDetails = ({
  event_id,
  order_id,
  isPairing,
  openOrder,
  openMap,
  markers,
  setOpenOrder,
  setOpenMap,
  approve,
  refuse,
  incidentBadgeContent,
  incidentBadgeColor,
  openHistoryPopup
}) => <>
    <DialogManager order_id={order_id} event_id={event_id} />
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <ExpandPanelComponent open>
          <ExpandPanelTitle>
            <IncidentHeader
              event_id={event_id}
              approve={approve}
              refuse={refuse}
              badgeContent={incidentBadgeContent}
              badgeColor={incidentBadgeColor}
              badgeClick={openHistoryPopup}
            />
          </ExpandPanelTitle>
          <ExpandPanelContent>
            <Grid container>
              <Grid item xs={12} hidden={!!isPairing}><DefaultIncident event_id={event_id} /></Grid>
              <Grid item xs={12} hidden={!isPairing}><PairingIncident event_id={event_id} /></Grid>
            </Grid>
          </ExpandPanelContent>
        </ExpandPanelComponent>
      </Grid>
      <Grid item xs={12} hidden={!order_id}>
        <ExpandPanelComponent open={openOrder} toggleOpen={setOpenOrder}>
          <ExpandPanelTitle>
            <OrderHeader
              order_id={order_id}
              enableList={false}
              headerLabelProps={{ sx: { fontSize: '1.2vw' } }}
            />
          </ExpandPanelTitle>
          <ExpandPanelContent> <OrderDetails order_id={order_id} disableHeader /></ExpandPanelContent>
        </ExpandPanelComponent>
      </Grid>
      <Grid item xs={12} hidden={!order_id}>
        <ExpandPanelComponent open={openMap} toggleOpen={setOpenMap}>
          <ExpandPanelTitle><MapHeader /></ExpandPanelTitle>
          {/* <ExpandPanelContent><MapComponent mapStyle={{ height: '400px' }} mapId='orderScreen' ungroupedMarker={markers} /></ExpandPanelContent> */}
        </ExpandPanelComponent>
      </Grid>
    </Grid >
  </>

export default React.memo(IncidentDetails)
