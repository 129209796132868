import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [audience] = hooks.useFieldValues(['audience'])

  const mergedProps = {
    audience
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
