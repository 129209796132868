import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Button, Grid } from '@mui/material'
import { OptionSelectorField, DateField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={9}>
    <Typography variant={'h5'}> {I18n.t('export.manual_title')} </Typography>
  </Grid>

const Row1 = ({ identity, ...props }) =>
  <Grid container item sx={12} spacing={2} justifyContent='center' alignItems='center' >
    <Grid item xs={1}>
      <DateField
        name='start_date'
        label={I18n.t('date.start')}
        required
      />
    </Grid>
    <Grid item xs={1}>
      <DateField
        name='end_date'
        label={I18n.t('date.end')}
        required
      />
    </Grid>
    <Grid item xs={3} hidden={identity !== 'FL'}>
      <OptionSelectorField
        name='identity'
        label={I18n.t('user.label', { count: 1 })}
        valueType={'checkbox'}
        options={[
          { label: I18n.t('identity.fl'), value: 'FL' },
          { label: I18n.t('identity.ap'), value: 'AP' },
          { label: I18n.t('identity.tv'), value: 'TV' },
          { label: I18n.t('identity.hp'), value: 'HP' }
        ]}
      />
    </Grid>
    <Grid item xs={5}>
      <OptionSelectorField
        name='billing_status'
        label={I18n.t('status.label', { count: 1 })}
        valueType={'checkbox'}
        multiple
        options={[
          { label: I18n.t('billing.status.to_check'), value: 'to_check' },
          { label: I18n.t('billing.status.to_validate'), value: 'to_validate' },
          { label: I18n.t('billing.status.valid'), value: 'valid' },
          { label: I18n.t('billing.status.transmit'), value: 'transmit' },
          { label: I18n.t('billing.status.closed'), value: 'closed' }
        ]}
        required
      />
    </Grid>
  </Grid>

const RowActions = ({ requestSubmit, ...props }) =>
  <Grid container item xs={12} spacing={1} justifyContent='center' alignItems='center' >
    <Button color='primary' size='large' onClick={requestSubmit} variant='contained'>
      {I18n.t('action.export')}
    </Button>
  </Grid>

const Form = props =>
  <Grid container rowSpacing={3}>
    <Grid item xs={12}>
      <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
        <Grid container item xs={12} rowSpacing={3}>
          <Title />
          <Row1 {...props} />
        </Grid>
      </Box>
    </Grid>
    <RowActions {...props} />
  </Grid>


export default React.memo(Form)
