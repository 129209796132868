import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Button, Grid } from '@mui/material'

import { OptionSelectorField, ModelMenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={9}>
    <Typography variant={'h5'}> {I18n.t('group.billing.export')} </Typography>
  </Grid>

const Row1 = (props) =>
  <Grid container item xs={12} spacing={1} justifyContent='center' alignItems='center' >
    <Grid item xs={3}>
      <OptionSelectorField
        name='group_member_status'
        label={I18n.t('group.status')}
        options={[
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.inactive'), value: 'inactive' }
        ]}
        multiple
        required
        getOptionLabelProps={() => ({ sx: { color: 'text.label' } })}
      />
    </Grid>
    <Grid item xs={5}>
      <ModelMenuSelectorField
        name='billing_category'
        label={I18n.t('contract.category')}
        model_name='billingcategory'
        labelKeys={['name_translated']}
        searchMode='search'
        loadOnMount
        multiple
        required
      />
    </Grid>
  </Grid>

const RowActions = ({ requestSubmit, ...props }) =>
  <Grid container item xs={12} spacing={1} justifyContent='center' alignItems='center' >
    <Button color='primary' size='large' onClick={requestSubmit} variant='contained'>
      {I18n.t('action.export')}
    </Button>
  </Grid>

const Form = props =>
  <Grid container rowSpacing={3}>
    <Grid item xs={12}>
      <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
        <Grid container item xs={12} rowSpacing={3}>
          <Title />
          <Row1 {...props} />
        </Grid>
      </Box>
    </Grid>
    <RowActions {...props} />
  </Grid>

export default React.memo(Form)
