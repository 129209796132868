import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [audience] = hooks.useFieldValues(['audience'])

  const resetRecivers = useCallback(value => setFormValues([
    { field: 'vehicle_options', value: undefined },
    { field: 'driver_options', value: undefined },
    { field: 'is_paired', value: value === 'all' ? true : undefined },
    { field: 'drivers', value: undefined }
  ]), [setFormValues])

  const mergedProps = {
    audience,
    resetRecivers
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
