import { I18n } from '@front/volcanion'

class Callbacks {

  static sendCommentHandler(openNotification, createComment, order_id) {
    return async function sendComment(values) {
      const { text, type, title } = values || {}

      try {
        if (!text)
          openNotification(I18n.t('toast.comment.error.empty'), { variant: 'warning' })
        else
          await createComment({ data: { text, type, title, category: 'JFPNk9Qxj', orders: [order_id] } })
      } catch (err) {
        openNotification(I18n.t('toast.comment.error.default'), { variant: 'error' })
      }
    }
  }

  static confirmPairingHandler(openNotification, approve) {
    return async function confirmPairing(values) {
      const { event_id } = values
      try {
        await approve(event_id)
        openNotification(I18n.t('toast.incident.success.approve'), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('toast.incident.error.approve'), { variant: 'error' })
      }
    }
  }

  static refuseHandler(openNotification, refuseIncident, event_id) {
    return async function refuse(values, extra, meta, state) {
      const { message } = values || {}

      try {
        await refuseIncident(event_id, !!message ? { message } : null)
        openNotification(I18n.t('toast.incident.success.refuse'), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('toast.incident.error.refuse'), { variant: 'error' })
      }
    }
  }
}

export default Callbacks
