import { I18n } from '@front/volcanion'

class Callbacks {
  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        title: _.get(values, 'title'),
        body: _.get(values, 'body'),
        type: _.get(values, 'type'),
        drivers: _.get(values, 'drivers'),
        vehicle_options: _.get(values, 'vehicle_options'),
        driver_options: _.get(values, 'driver_options'),
        vehicletypes: values?.vehicletypes,
        is_paired: _.get(values, 'is_paired') !== null ? _.get(values, 'is_paired') : undefined
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        type: 'alert',
        audience: 'all',
        is_paired: true
      }
    }
  }

  static onSubmitSuccessHandler(navigate) {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
      navigate('/message')
    }
  }

  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }

  static goBackHandler(goBackPath, navigate) {
    return function goBack(event) {
      navigate(goBackPath || -1)
      event.stopPropagation()
    }
  }

  static onSubmitHandler(isAudienceContainAllDriver, requestSubmit, openDialog) {
    return function onSubmit(event) {
      if (!!isAudienceContainAllDriver) return openDialog('warning_comfirmation_audience', {
        title: I18n.t('broadcast.popup.title'),
        description: I18n.t('broadcast.popup.description')
      })
      requestSubmit()
      event.stopPropagation()
    }
  }
}

export default Callbacks
