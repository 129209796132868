import { I18n } from '@front/volcanion'
import { OrangeContact } from '../../..'
class Callbacks {
  static buildVehicleMarkerHandler(vehicle) {
    return function buildVehicleMarker() {
      if (_.isEmpty(_.get(vehicle, 'current_location.coordinates'))) return undefined
      return ({
        type: 'vehicle',
        marker_id: _.get(vehicle, 'vehicle_id'),
        coordinates: _.get(vehicle, 'current_location.coordinates'),
        capturedAt: _.get(vehicle, 'current_location.capturedAt'),
        bearing: _.get(vehicle, 'current_location.bearing'),
        vehicle: {
          plaque: _.get(vehicle, 'info.plaque'),
        },
        icon: {
          name: 'vehicle'
        }
      })
    }
  }
  static buildAddressMarkerHandler(address, addressType) {
    return function buildVehicleMarker() {
      if (_.isEmpty(_.get(address, 'coordinates'))) return undefined
      return ({
        type: 'address',
        markerId: _.get(address, 'place_id') || addressType,
        coordinates: _.get(address, 'coordinates'),
        icon: {
          name: 'pin',
          color: addressType === 'destination' ? 'red' : 'green'
        }
      })
    }
  }
  static handleCallHandler(EXTERNAL_PHONE_SERVICE, orangeCall) {
    return async function call(number) {
      if (!number) return
      if (EXTERNAL_PHONE_SERVICE === 'aws') {
        const endpoint = connect.Endpoint.byPhoneNumber(number)
        const agent = new connect.Agent()
        const contacts = agent.getContacts()
        if (_.isEmpty(contacts))
          agent.connect(endpoint)
        else if (contacts.length === 1)
          contacts[0].addConnection(endpoint)
      }
      else if (EXTERNAL_PHONE_SERVICE === 'orange') {
        const phoneNumber = encodeURIComponent(number)
        await orangeCall(phoneNumber)
      }
    }
  }
  static handleOrangeCallHandler() {
    return async function handleOrangeCall(phoneNumber) {
      return OrangeContact.execute('GET', 'clickToCall', { phoneNumber })
    }
  }

  static approveHandler(openNotification, openDialog, approveIncident, incident, incidentBadgeColor) {
    return async function approve() {
      const { event_id, name } = incident
      const vehicle_driver_id = incident?.vehicle?.driver?.user_id
      const driver_id = incident?.vehicle?.parent_licence?.usual_vehicle?.driver?.user_id
      if (name === 'pairing_request' && (!!driver_id || !!vehicle_driver_id)) {
        const pairing_type = driver_id !== vehicle_driver_id && !!driver_id ? 'contract' : 'vehicle'
        openDialog('confirm_paring_request', {
          description: I18n.t(`popup.confirm.pairing.${pairing_type}`, {
            oldDriver: incident?.vehicle?.driver?.info?.user_client_id,
            newDriver: incident?.driver?.info?.user_client_id,
          })
        }, { event_id })
      } else if (incidentBadgeColor === 'error') {
        openDialog('confirm_approve', { description: I18n.t('incident.limit_warning', { name: _.toLower(I18n.t(`incident.${name}`, { count: 1 })) }) })
      } else {
        try {
          await approveIncident(event_id)
          openNotification(I18n.t('toast.incident.success.approve'), { variant: 'success' })
        } catch (err) {
          openNotification(I18n.t('toast.incident.error.approve.default'), { variant: 'error' })
        }
      }
    }
  }

  static refuseHandler(openDialog) {
    return function refuse() {
      openDialog('incident_refuse')
    }
  }

  static openHistoryPopupHandler(openDialog) {
    return function openHistoryPopup() {
      openDialog('linked_incident')
    }
  }

}

export default Callbacks
