import { I18n } from '@front/volcanion'

class Callbacks {
  static onKeyDownHandler(ctrlKeyMap, altKeyMap, baseKeyMap) {
    return function onKeyDown(e) {
      const { altKey, ctrlKey } = e
      !!ctrlKey && ctrlKeyMap(e)
      !!altKey && altKeyMap(e)
      !altKey && !ctrlKey && baseKeyMap(e)
    }
  }
  static ctrlKeyMapHandler(record, extra, extraFunctions) {
    return async function ctrlKeyMap(event) {
      const { incident } = extra
      const { key } = event
      const { openNotification, refuse } = extraFunctions
      if (key === 'F12') {
        try {
          event.preventDefault()
          refuse(incident)
        } catch (err) {
          openNotification(I18n.t('toast.incident.error.refuse.default'), { variant: 'error' })
        }
      } else {
        return false
      }
    }
  }
  static altKeyMapHandler(record, extra, extraFunctions) {
    return async function altKeyMap(event) {
      const { key } = event
      const { customer_number } = extra
      const driver_id = _.get(record, 'assigned_transport.driver.user_id')

      switch (key) {
        case 'F3':
          if (!customer_number) return
          event.preventDefault()
          window.open(`/order?customer_number=${_.replace(customer_number, '+', '')}`, '_blank')
          break;
        case 'F10':
          if (!driver_id) return
          event.preventDefault()
          window.open(`/driver?user_id=${driver_id}`, '_blank')
          break;
        default:
      }
    }
  }
  static baseKeyMapHandler(record, extra, extraFunctions) {
    return async function baseKeyMap(event) {
      const { key } = event
      const {
        handleCall,
        setOpenOrder,
        setOpenMap,
        openNotification,
        approve,
        openDialog
      } = extraFunctions
      const {
        openOrder,
        openMap,
        driver_number,
        customer_number,
      } = extra

      switch (key) {
        case 'F1':
          openDialog('incident_short_cut')
          event.preventDefault()
          break;
        case 'F2':
          openDialog('order_send_comment')
          event.preventDefault()
          break;
        case 'F3':
          setOpenOrder(!openOrder)
          event.preventDefault()
          break;
        case 'F5':
          setOpenMap(!openMap)
          event.preventDefault()
          break;
        case 'F7':
          event.preventDefault()
          !!driver_number
            ? await handleCall(driver_number)
            : openNotification(I18n.t('toast.call.error.forbidden'), { variant: 'error' })
          break;
        case 'F9':
          event.preventDefault()
          !!customer_number
            ? await handleCall(customer_number)
            : openNotification(I18n.t('toast.call.error.forbidden'), { variant: 'error' })
          break;
        case 'F12':
          event.preventDefault()
          approve()
          break;
        default:
      }
    }
  }
}

export default Callbacks
