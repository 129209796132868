import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { Link, TableCell, Typography } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'
import OrderUtils from '@front/volcanion/utils/order'

import AssociatedInfoComponent from '@abra/elements/event/Data/'

const OrderClientIDCell = ({ color, row }) =>
  <Typography
    sx={{
      color,
      fontWeight: 800,
      fontSize: 'subtitle1.fontSize',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Link sx={{ color: 'inherit' }}
      target='_blank'
      onClick={(e) => {
        window.open(`/order/${_.get(row, 'order.order_id')}`, '_blank')
        e.stopPropagation()
      }}
    >
      {_.get(row, 'order.order_client_id') || '-'}
    </Link>
  </Typography>

const Row = ({ row }) => {
  const type = OrderUtils.getOrderType(_.get(row, 'order.do.auth.application.name'), _.get(row, 'order.commercial_package.load_type'))
  const [{ name: operator_name }] = hooks.useCustomerInfo(row?.operator?.user_id)
  const hasOrder = !!_.get(row, 'order')
  const hasData = !!_.get(row, 'data')

  return <>
    <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'producedAt'))} /></TableCell>
    <TableCell size={'small'}><TextCell value={operator_name} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'driver.info.user_client_id')} /></TableCell>
    <TableCell size={'small'}>
      {!!hasOrder
        ? <OrderClientIDCell color={OrderUtils.getColorTheme(type)} row={row} />
        : <TextCell value={'-'} />
      }
    </TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`event.${_.get(row, 'name')}`)} /></TableCell>
    <TableCell size={'small'}>
      {!!hasData
        ? <AssociatedInfoComponent row={row} />
        : <TextCell value={'-'} />
      }
    </TableCell>
  </>
}

export default React.memo(Row)
