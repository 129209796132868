import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { job_id } = hooks.useParams()
  const formProps = {
    name: 'job_form',
    model_name: 'job',
    record_id: job_id,
    related_states: [],
    related_searches: [],
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [
      'payment',
      'first_step.order.vehicle_opts',
      'first_step.order.driver_opts',
      'first_step.order.do.auth.application',
      'first_step.order.commercial_package',
      'first_step.order.loads'
    ],
    initialState: { isReadOnly: !!job_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }
  const mergedProps = {
  }
  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
