import React from 'react'
import { I18n } from '@front/volcanion'

import { FormDialog, DialogContent, ConfirmationDialog, InformationDialog } from '@front/squirtle'
import ShortcutHelper from '@abra/elements/Popup/ShortcutHelper'

import { SendCommentPopup, RefusePopup, shortcuts, HistoryPopup } from './'

const IncidentDetails = ({ confirmPairing, sendComment, refuse, approve }) => <>

  <InformationDialog name={'incident_short_cut'} maxWidth={'lg'}>
    <DialogContent><ShortcutHelper shortcuts={shortcuts} /></DialogContent>
  </InformationDialog>

  <InformationDialog name={'linked_incident'} maxWidth={'xl'}>
    <DialogContent><HistoryPopup /></DialogContent>
  </InformationDialog>

  <ConfirmationDialog name={'confirm_paring_request'} onConfirm={confirmPairing} title={I18n.t('toast.warning.label', { count: 1 })} />
  <ConfirmationDialog name={'confirm_approve'} onConfirm={approve} title={I18n.t('toast.warning.label', { count: 1 })} />

  <FormDialog name={'order_send_comment'} title={I18n.t('comment.label', { count: 1 })} formProps={{ onSubmit: sendComment }}>
    <DialogContent><SendCommentPopup /></DialogContent>
  </FormDialog>

  <FormDialog
    name={'incident_refuse'}
    title={I18n.t('incident.popup.refuse.title')}
    descritpion={I18n.t('incident.popup.refuse.description')}
    formProps={{ onSubmit: refuse }}
  >
    <DialogContent><RefusePopup /></DialogContent>
  </FormDialog>

</>

export default React.memo(IncidentDetails)
