import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Equipment from './Details'
import EquipmentList from './List'

const EquipmentPage = props =>
  <Routes>
    <Route index element={<EquipmentList />} />
    <Route path='/create' element={<Equipment />} />
    <Route path='/:equipment_id' element={<Equipment />} />
  </Routes>

export default React.memo(EquipmentPage)
