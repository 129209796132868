import BillingUtils from '@front/volcanion/utils/billing'
import FormatUtils from '@front/squirtle/utils/format'
import Aux from './Aux'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      const jobPrice = BillingUtils.getPaymentDisplay(_.get(record, 'payment'), true, 2)
      const hasPrice = !_.includes([
        'cp_id_taxibus',
        'cp_id_taxibus_pmr',
        'cp_id_stib_direct',
        'cp_id_stib_grouped'
      ], _.get(record, 'first_step.order.commercial_package'))

      return {
        price: {
          order: !!hasPrice ? jobPrice : undefined,
          cashed: !!hasPrice ? Aux.getPriceByPassenger(record) : undefined,
          driver: !hasPrice ? jobPrice : undefined
        },
        distance: FormatUtils.distance(_.get(record, 'payment.distance')),
        commercial_package: _.get(record, 'first_step.order.commercial_package.commercial_package_id'),
        vehicle_opts: _.map(_.get(record, 'first_step.order.vehicle_opts'), opt => opt.vehicle_option_id),
        driver_opts: _.map(_.get(record, 'first_step.order.driver_opts'), opt => opt.user_option_id),
        requestedAt: _.get(record, 'first_step.order.requestedAt')
      }
    }
  }

  static formToRecordHandler(specificparameter_id, companycontract) {
    return function formToRecord(values, extra, meta) {
      return { ...values }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'first_step.order.order_client_id'),
        status: _.get(record, 'status'),
        type: _.get(record, 'first_step.order.do.auth.application.name') === 'Collecto'
          ? 'collecto'
          : _.get(record, 'first_step.order.commercial_package.load_type')
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
