import React from 'react'

import { hooks } from '@front/volcanion'
import { Box } from '@mui/material'
import GroupDetails from '@abra/frames/Group'

const GroupPage = props => {
  const { group_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <GroupDetails record_id={group_id} />
    </Box>
  )
}

export default React.memo(GroupPage)
