
import React from 'react'

import { hooks } from '@front/volcanion/'

const withContainer = Component => props => {
  const commercial_package_id = hooks.useFieldValue(['commercial_package'])
  const [commercial_package] = hooks.useModel('commercialpackage', [commercial_package_id], { single: true, populate: [] })

  const mergedProps = {
    client_type_icon: _.get(commercial_package, 'client_type_icon')
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
