import moment from 'moment'

class Callbacks {
  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      const start_date = moment().startOf('month').format()
      const end_date = moment().endOf('month').format()
      return {
        billing_status: 'valid',
        start_date,
        end_date
      }
    }
  }

  static onSubmitHandler(billingExport) {
    return async function onSubmit(values) {
      await billingExport(values)
    }
  }
}

export default Callbacks
