import React, { useState } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const onSubmit = hooks.useFormFunction('requestSubmit')
  const [nameOpen, setNameOpen] = useState(true)
  const [formulaOpen, setFormulaOpen] = useState(false)

  const mergedProps = {
    onSubmit,
    nameOpen,
    formulaOpen,
    setNameOpen,
    setFormulaOpen,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
