import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, ...props }) => <>
  <TableCell size={'small'}><TextCell value={_.get(row, 'group_client_id')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'address')} /></TableCell>
  <TableCell size={'small'}>
    <TextCell
      value={_.get(row, 'member_status') ? I18n.t('status.active') : I18n.t('status.unactive')}
      sx={{ color: !!_.get(row, 'member_status') ? 'green' : 'red' }}
    />
  </TableCell>
</>

export default React.memo(Row)
