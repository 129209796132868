import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Group from './Details'
import GroupList from './List'
import License from '@abra/pages/License'
import Vehicle from '@abra/pages/Vehicle'
import Driver from '@abra/pages/Driver'

const GroupPage = props =>
  <Routes>
    <Route index element={<GroupList />} />
    <Route path='/create' element={<Group />} />
    <Route path='/:group_id' element={<Group />} />

    <Route path='/:group_id/license/*' element={<License />} />
    <Route path='/:group_id/vehicle/*' element={<Vehicle />} />
    <Route path='/:group_id/driver/*' element={<Driver />} />
  </Routes>


export default React.memo(GroupPage)
