
import React from 'react'

import { hooks } from '@front/volcanion/'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')

  const mergedProps = {
    record_id
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
